html,
body,
.App,
#root {
	height: 100%;
}

.App {
	text-align: center;
}

.canvas-feed {
	display: none;
}

video {
	position: fixed;
	right: 0;
	bottom: 0;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	z-index: -100;
	background-size: cover;
}

.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 40vmin;
	pointer-events: none;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

.bp3-dialog-container {
	padding: 10px;
}

.bp3-large.bp3-button.switch-button {
	background-color: #f5f8fa94;
	border-radius: 50%;
	padding: 35px 40px;
}

.swap-cam-container {
	position: absolute;
	bottom: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.context-menu-node {
	border: 4px solid #fff;
	border-radius: 50%;
	box-shadow: 0 0 0 1px rgba(16, 22, 26, .1), 0 1px 1px rgba(16, 22, 26, .2), 0 2px 6px rgba(16, 22, 26, .2);
	background-color: #48aff0;
	width: 80px;
	height: 80px;
	overflow: hidden;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
